body {
    line-height: 26px;
    margin: 0;
  }
  
  form {
    margin-top: 20px;
  }
  
  .formRow {
    margin: 10px 0 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .formInput {
    padding: 15px 10px;
    font-family: 'Garamond', serif;
    font-size: 16px;
    border-radius: 10px; /* Add this line for rounded edges */
  }

  @media (max-width: 768px) {
    .formInput {
      font-size: 12px;
    }
  }
  
  .errorMessage {
    color: #ffffff;
    font-size: 16px;
    margin: 0;
    padding: 0;
  }
  
  .submit-btn {
    padding: 15px 50px;
    border-radius: 10px;
    margin-bottom: 25px;
    font-family: 'Garamond', serif;
    font-size: calc(.5em + .75vw);
    background-color: #e2e2e2;
    max-width: 75%;
    box-shadow: 0 0 5px 5px rgba(81, 82, 66, 0.3);
  }

  @media (max-width: 768px) {
    .submit-message {
      width: 125px;
      margin-left: 200px;
    }
  }

.col-6 {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.col {
  display: flex;
  flex-direction: column;
  width: 75%;
}

.submit-text {
  text-shadow: 1px 1px 5px rgba(81, 82, 66, 0.3);
}