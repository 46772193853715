
/* 
cream: #c9c9c9
black: #000000

google fonts colors: 
#1f2125
#fefefe

pi colors:
#fbf2ea
#0f3c27

substack: 
#f1f2e2
#515242
text - rgb(81, 82, 66);
*/

:root {
  --background-color: #f1f2e2;
  --background-color-rgb: 241, 242, 226;
  --text-color: rgb(58, 58, 46);
  --shadow-color: rgba(81, 82, 66, 0.3);
  --edge-opacity: 0.95;
  --link-color: #6824d5;
  --link-hover-color: #441161;
}

/* not using light mode and dark mode for now */
.light-mode {
  --background-color: #f1f2e2;
  --background-color-rgb: 241, 242, 226;
  --text-color: rgb(58, 58, 46);
  --shadow-color: rgba(81, 82, 66, 0.3);
  --edge-opacity: 0.95;
}

.dark-mode {
  --background-color: #1f2125;
  --background-color-rgb: 31, 33, 37;
  --text-color: #fefefe;
  --shadow-color: rgba(81, 82, 66, 0.3);
  --edge-opacity: 0.95;
}

html, body {
  height: 100%;
  margin-top: 10%;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: "EB Garamond", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;

}

.App {
  background-color: var(--background-color);
  color: var(--text-color);
  text-align: center;
  min-height: 100%;
  min-width: 100%;
  margin: 50px;
  padding-left: 0%;
  padding-right: 0%;
  margin: 0%;
}

.about-container {
  margin: 100px;
  margin-left: 15%;
  margin-right: 15%;
  margin-bottom: 100px;
}

@media (max-width: 768px) {
  .about-container {
    margin-top: 40%;
    margin-right: 10%;
    margin-left: 10%;
  }
}

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}

@media (max-width: 768px) {
  .contact-container {
    margin-top: 40%;
  }
}

.games-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 5%;
  padding-bottom: 10%;
}

@media (max-width: 768px) {
  .games-container {
    margin-top: 40%;
  }
}

.game {
  display: flex;
  height: 55%;
  width: 55%;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .game {
    width: 90%;
  }
}

/* header, footer, and navbar */

.edge-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 10px;
  align-items: center;
  text-align: center;
  background-color: rgba(var(--background-color-rgb), var(--edge-opacity));
  box-shadow: 0 0 5px 5px rgba(var(--background-color-rgb), var(--edge-opacity));
  border: 0px solid black;
  margin: 0px;
}

.header-container {
  top: 0;
  height: 15%;
}

.footer-container {
  bottom: 0;
  height: 10%;
}


@media (max-width: 768px) {
  .footer-container {
    height: 15%;
  }
}

.navbar {
  width: 85%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: calc(.5em + .9vw);
}

@media (max-width: 768px) {
  .navbar {
    font-size: calc(.7em + .9vw);
  }
}

.nav-item {
  margin: 20px;
  text-shadow: 1px 1px 5px rgba(81, 82, 66, 0.3);
}

@media (max-width: 768px) {
  .nav-item {
    margin: 10px;
  }
}

.nav-link {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit color from parent */
  user-select: none; /* Prevent text selection */
  cursor: pointer; /* Change cursor on hover */
}

.nav-icon {
  color: var(--text-color);
  width: 50px;
  height: auto;
  /* box-shadow: 2px 2px 5px rgba(81, 82, 66, 0.5);
  border-radius: 10px; */
  /* -webkit-filter: drop-shadow(5px 5px 5px #222); */
  filter: drop-shadow(2px 2px 5px rgba(81, 82, 66, 0.3));

}

/* portfolio */

.portfolio-img {
  width: 50%;
  min-width: 300px;
  height: auto;
  border-radius: 30px;
  box-shadow: 0 0 15px rgba(81, 82, 66, 0.3); /* Add shadow for feathered edges */
  user-select: none; /* Prevent image selection */
}

/* footer */


.container {
  border: #000000 solid 0px;
  padding-left: 0%;
  padding-right: 0%;
}

.footer {
  width: 33%;
}

@media (max-width: 768px) {
  .footer {
    width: 100%;
  }
}



.th, th {
  text-align: center;
}

.td, td {
  text-align: center;
}


/* text */ 

.header-text {
  font-size: calc(.5em + 1.5vw);
  line-height: 1.0;
  text-shadow: 2px 2px 5px rgba(81, 82, 66, 0.3);
}

.paragraph-text {
  font-size: calc(.8em + .45vw);
  line-height: 1.5;
  text-shadow: 1px 1px 5px rgba(81, 82, 66, 0.3);
}


.welcome-text {
  font-size: calc(.7em + 1vw);
  line-height: 1.5;
  text-shadow: 1px 1px 5px rgba(81, 82, 66, 0.3);
  text-align: left;
  user-select: none;
  margin: 100px;
  margin-left: 15%;
}

@media (max-width: 768px) {
  .welcome-text {
    margin-top: 40%;
    margin-right: 10%;
    margin-left: 10%;
  }
}

.link-text {
  text-decoration: none;
  color: var(--link-color);
}

.link-text:hover {
  color: var(--link-hover-color);
}

.link-text:visited {
  color: var(--link-color);
}

.about-header {
  font-size: calc(.5em + 1.5vw);
  line-height: 1.0;
  text-shadow: 2px 2px 5px rgba(81, 82, 66, 0.3);
}

.contact-me {
  text-shadow: 1px 1px 5px rgba(81, 82, 66, 0.3);
}

/* game gifs */

.image-container {
  position: relative;
  display: inline-block;
  margin: 10px;
  height: 100%;
  width: 100%;
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
  user-select: none;
  cursor: pointer;
}



.hover-gif {
  transition: filter 0.3s;
  cursor: pointer;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 15px rgba(81, 82, 66, 0.3); /* Add shadow for feathered edges */
}

.crash-gif {
  clip-path: polygon(3% 0, 7% 1%, 11% 0%, 16% 2%, 20% 0, 23% 2%, 28% 2%, 32% 1%, 35% 1%, 39% 3%, 41% 1%, 45% 0%, 47% 2%, 50% 2%, 53% 0, 58% 2%, 60% 2%, 63% 1%, 65% 0%, 67% 2%, 69% 2%, 73% 1%, 76% 1%, 79% 0, 82% 1%, 85% 0, 87% 1%, 89% 0, 92% 1%, 96% 0, 98% 3%, 99% 3%, 99% 6%, 100% 11%, 98% 15%, 100% 21%, 99% 28%, 100% 32%, 99% 35%, 99% 40%, 100% 43%, 99% 48%, 100% 53%, 100% 57%, 99% 60%, 100% 64%, 100% 68%, 99% 72%, 100% 75%, 100% 79%, 99% 83%, 100% 86%, 100% 90%, 99% 94%, 99% 98%, 95% 99%, 92% 99%, 89% 100%, 86% 99%, 83% 100%, 77% 99%, 72% 100%, 66% 98%, 62% 100%, 59% 99%, 54% 99%, 49% 100%, 46% 98%, 43% 100%, 40% 98%, 38% 100%, 35% 99%, 31% 100%, 28% 99%, 25% 99%, 22% 100%, 19% 99%, 16% 100%, 13% 99%, 10% 99%, 7% 100%, 4% 99%, 2% 97%, 1% 97%, 0% 94%, 1% 89%, 0% 84%, 1% 81%, 0 76%, 0 71%, 1% 66%, 0% 64%, 0% 61%, 0% 59%, 1% 54%, 0% 49%, 1% 45%, 0% 40%, 1% 37%, 0% 34%, 1% 29%, 0% 23%, 2% 20%, 1% 17%, 1% 13%, 0 10%, 1% 6%, 1% 3%); 
}

.dudo-perudo-gif {
  border-radius: 50px;
}

.image-container:hover .hover-gif {
  filter: brightness(0.4); /* Darken the GIF */
}

.image-container:hover .overlay-text {
  opacity: 1; /* Show the text on hover */
}

/* contact */

.contact-card {
  box-shadow: 0 0 15px  rgba(81, 82, 66, 0.3); /* Add shadow for feathered edges */
  width: 33%;
}

@media (max-width: 768px) {
  .contact-card {
    width: 85%;
  }
}